import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from 'components/Banner';
import {
    fetchDetailTrialResults,
    selectDetailTrialResults,
    fetchTrialResultsPageList,
    selectTrialResultsPageList,
    fetchTrialResultsFlyers,
    selectTrialResultsFlyers,
    selectDetailTrialIsLoading,
    selectCurrentProductFilterForTrialResults,
    selectCurrentCountryFilterForTrialResults,
} from 'store/slices/TrialResults';
import WithSidebar from 'Layout/WithSidebar';
import PDFBox from 'components/PDFBox';
import ProductSidebar from 'components/ProductSidebar';
import { ROUTES } from 'constants/routes';
import ButtonShowMore from 'components/Button/ButtonShowMore';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';
import { scrollToSection, with404Page } from 'helpers';
import FilterByProduct from './FilterByProduct';
import { fetchTrialResults } from 'store/slices/TrialResults';
import GradientText from 'components/GradientText';

const DetailedTrialResults = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [page, setPage] = useState(1);
    const { flyers, next } = useSelector(selectTrialResultsFlyers);
    const data = useSelector(selectDetailTrialResults);
    const crops = useSelector(selectTrialResultsPageList);
    const { sidebar_text_trials_result } = useSelector(selectTranslations);
    const loading = useSelector(selectDetailTrialIsLoading);
    const { title, fs_background, fs_view_button, no_trial_result } = data;

    const currentProductFilter = useSelector(selectCurrentProductFilterForTrialResults);
    const currentCountryFilter = useSelector(selectCurrentCountryFilterForTrialResults);

    useEffect(() => {
        setPage(1);
        // @ts-ignore
        dispatch(fetchTrialResultsPageList({ all: true }));
        with404Page(
            // @ts-ignore
            dispatch(fetchDetailTrialResults({ slug })),
            navigate,
        );
        // @ts-ignore
        dispatch(fetchTrialResults());
    }, [dispatch, slug]);

    useEffect(() => {
        setPage(1);
    }, [dispatch, currentProductFilter, currentCountryFilter]);

    useEffect(() => {
        dispatch(
            // @ts-ignore
            fetchTrialResultsFlyers({
                slug,
                page,
                product: currentProductFilter?.value,
                trials_country: currentCountryFilter?.value,
            }),
        );
    }, [dispatch, page, slug, currentProductFilter, currentCountryFilter]);

    const handleShowMore = () => setPage(page + 1);

    const handleClick = () => scrollToSection('this');

    const container = useRef();

    useAnimation({
        items: ['.box'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner title={title} image={fs_background} buttonText={fs_view_button} onClickButton={handleClick} />
            <section className="container this">
                <WithSidebar
                    containerType="type-1"
                    Sidebar={
                        <>
                            <FilterByProduct />
                            <ProductSidebar
                                title={sidebar_text_trials_result}
                                data={crops}
                                baseRoute={ROUTES.trialResults}
                            />
                        </>
                    }
                >
                    {
                        !flyers.length
                            ? (
                                <>
                                    <GradientText className="has-animation">
                                        <span dangerouslySetInnerHTML={{ __html: no_trial_result.title }} />
                                    </GradientText>
                                    <div className="offset-left-4 max-width-760">
                                        <GradientText size="super-small" className="has-animation">
                                            <span dangerouslySetInnerHTML={{ __html: no_trial_result.description }} />
                                        </GradientText>
                                    </div>
                                </>
                            )
                            : (
                                <PDFBox data={flyers}>
                                    <ButtonShowMore onClick={handleShowMore} next={next} />
                                </PDFBox>
                            )
                    }
                </WithSidebar>
            </section>
        </div>
    );
};

export default DetailedTrialResults;
